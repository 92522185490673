<template>
  <div>
    <div
      ref="refsViewMoreContainer"
      class="d-flex flex-column flex-gap-md corporate-directory-details px-0 px-lg-1"
      style="height: 85vh; overflow-y: auto; overflow-x: none;"
    >
      <b-overlay
        :show="$isEmpty(corporateDetails.chart_data.recruitment_activities)"
        style="height: 200px;"
      >
        <div
          v-if="$isNotEmpty(corporateDetails.chart_data.recruitment_activities)"
          id="recruitmentActivities"
        >
          <ChartComponent
            :chart-data="corporateDetails.chart_data.recruitment_activities"
            :height="'200'"
            :chart-type="'line'"
            title="Recruitment Activities"
          />
        </div>
      </b-overlay>

      <div
        class="d-flex flwx-row flex-gap-md w-100"
        :class="$isMobile() ? `flex-wrap` : ''"
      >
        <div class="flex-fill m-1">
          <CustomAnalyticCard
            id="uniqueRoles"
            height="42vh"
            class="flex-fill mr-1 mr-lg-0 px-1"
            title="Unique Roles"
          >
            <table
              ref="refUniqueRoleTable"
              class="custom-table"
            >
              <thead>
                <tr>
                  <td>Role Title</td>
                </tr>
              </thead>

              <tbody
                v-if="$isNotEmpty(corporateDetails.chart_data.unique_roles)"
              >
                <tr
                  v-for="item, index in corporateDetails.chart_data.unique_roles"
                  :key="item.key"
                  :class="index%2 === 1 ? 'bg-primary bg-lighten-6' : ''"
                >
                  <td>{{ item.position }}</td>
                </tr>
              </tbody>

              <tbody
                v-else
              >
                <tr>
                  <td class="text-center no-result-height">
                    No Records
                  </td>
                </tr>
              </tbody>

            </table>
          </CustomAnalyticCard>
        </div>

        <div class="flex-fill m-1">
          <CustomAnalyticCard
            id="listedRoles"
            height="42vh"
            class="flex-fill mr-1 mr-lg-0 px-1"
            :style="`width: ${
              $isMobile()
                ? $refs.refUniqueRoleTable ? $refs.refUniqueRoleTable.clientWidth + 'px' : '100%'
                : '100%;'
            }; overflow-x: auto; overflow-y: none;`"
            title="Listed Roles"
          >
            <!-- :style="`width: ${$refs.refUniqueRoleTable ? $refs.refUniqueRoleTable.clientWidth +'px' : '100%'}; overflow-x: auto; overflow-y: none;`" -->

            <div>
              <table class="custom-table">
                <thead>
                  <tr>
                    <td style="min-width: 40ch;">
                      Role Title
                    </td>
                    <td style="min-width: 25ch;">
                      Public On
                    </td>
                    <td style="min-width: 20ch;">
                      Source
                    </td>

                    <td style="min-width: 20ch;">
                      Actions
                    </td>
                    <!-- <td>Publication Status</td> -->
                  </tr>
                </thead>

                <tbody
                  v-if="$isNotEmpty(corporateDetails.chart_data.listed_roles)"
                >
                  <tr
                    v-for="item, index in corporateDetails.chart_data.listed_roles"
                    :key="item.key"
                    :class="index%2 === 1 ? 'bg-primary bg-lighten-6' : ''"
                  >
                    <td>{{ item.position }}</td>
                    <!-- <td>
                      <CellRendererCategory :params="{publication_status: item.publication_status}" />
                    </td> -->
                    <td>{{ $formatDatetime(item.published_on) }}</td>
                    <td>{{ item.source_name.toUpperCase() }}</td>

                    <td>
                      <!-- <feather-icon
                        v-if="!item.paid"
                        icon="ShoppingCartIcon"
                        class="cursor-pointer text-primary mr-1"
                        size="30"
                        :class="item.shopping_cart ? 'fill-primary-lighten-3' : ''"
                      /> -->
                      <feather-icon
                        icon="BellIcon"
                        class="cursor-pointer text-primary"
                        size="30"
                        :class="{
                          'fill-primary-lighten-3': item.notification_enabled,
                        }"
                        @click="addToActiveMonitoring('position', item)"
                      />
                    </td>
                  </tr>
                </tbody>

                <tbody
                  v-else
                >
                  <tr>
                    <td
                      colspan="10"
                      class="text-center no-result-height"
                    >
                      No Records
                    </td>
                  </tr>
                </tbody>

              </table>
            </div>
          </CustomAnalyticCard>
        </div>
      </div>

      <div class="d-flex flex-row flex-wrap flex-gap-md mx-1">
        <CustomAnalyticCard
          id="employeeList"
          height="42vh"
          class="flex-fill mr-1 mr-lg-0 px-1"
          title="Employees (Based on Public Records)"
        >
          <table class="custom-table">
            <thead>
              <tr>
                <td>Name</td>
                <td>Position</td>
                <td>Years in Orgnisation</td>
              </tr>
            </thead>

            <tbody v-if="$isNotEmpty(corporateDetails.chart_data.employee_list)">
              <tr
                v-for="item, index in corporateDetails.chart_data.employee_list"
                :key="item.key"
                :class="index%2 === 1 ? 'bg-primary bg-lighten-6' : ''"
              >
                <td>{{ item.fullname }}</td>
                <td>{{ $isNotEmpty(item.position_in_company) ? item.position_in_company : '-' }}</td>
                <td>{{ $isNotEmpty(item.years_in_organisation) ? item.years_in_organisation : '-' }}</td>
              </tr>
            </tbody>

            <tbody v-else>
              <tr>
                <td
                  colspan="10"
                  class="text-center no-result-height"
                >
                  No Records
                </td>
              </tr>
            </tbody>

          </table>
        </CustomAnalyticCard>
      </div>
    </div>
  </div>
</template>

<script>
import ChartComponent from '@/components/ChartComponent.vue'
import CustomAnalyticCard from '@/components/CustomAnalyticCard.vue'
import CellRendererCategory from '@/views/event-management-system/EventManager/cell-renderer/CellRendererCategory.vue'

export default {
  components: {
    ChartComponent,
    CustomAnalyticCard,
    CellRendererCategory,
  },

  props: {
    data: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      isLoading: false,
      corporateDetails: {
        data: {},
        chart_data: {},
      },
    }
  },

  computed: {
  },

  watch: {
  },

  created() {
    this.getCorporateDetails()
  },

  mounted() {
    //
  },

  methods: {
    getCorporateDetails() {
      this.isLoading = true
      this.$http
        .get(`/api/corporate-details/${this.data.company_uen_no}`)
        .then(response => {
          const { success, output, message } = response.data

          if (success) {
            this.corporateDetails = output
          }
          this.isLoading = false
        })
        .catch(() => {
          //
          this.isLoading = false
        })
    },
    addToActiveMonitoring(section=null, record) {
      if (section === 'position') {
        const ssocList = []
        record.ssoc_list.forEach(ssoc => {
          ssocList.push(ssoc.ssoc_code)
        })
      }
    }
  },
}
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables/variables.scss";

.corporate-directory-details {

  #recruitmentActivities {
    width: 100%;
  }
}

.custom-table {
  width: 100%;

  thead {
    position: sticky;
    top: 0;
    line-height: 3.5rem;
    background: $primary;
    color: $white;
  }
  tbody {
    line-height: 3rem;
  }

  tbody tr:nth-child(even) {
    background: lighten($primary, 48%);
  }

  thead td {
    padding-inline: 1rem;
  }

  tbody td {
    padding-inline: 1rem;
    padding-block: 1rem;
    line-height: 1.5rem;
  }
  .row-summary {
    background: lighten($primary, 40%);
    font-weight: bolder;
    font-size: 1.5rem;
    line-height: 3.5rem;
  }
}
.no-result-height {
  // height: 10rem;
  align-content: center;
  padding-top: 2.5rem;
}
</style>
